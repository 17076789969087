
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    userAddress: String,
    totalSupply: Number,
    maxSupply: Number,
    isPaused: Boolean,
    isWhitelistMintEnabled: Boolean,
    isUserInWhitelist: Boolean,
    isSoldOut: Boolean
  }
})
export default class CollectionStatus extends Vue {
  userAddress!: string
  totalSupply!: number
  maxSupply!: number
  isPaused!: boolean
  isWhitelistMintEnabled!: boolean
  isUserInWhitelist!: boolean
  isSoldOut!: boolean

  get isSaleOpen (): boolean {
    return (this.isWhitelistMintEnabled || !this.isPaused) && !this.isSoldOut
  }
}
