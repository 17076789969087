import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Issue1 from '../views/Issue1.vue'
// import Viewer from '../views/Viewer.vue'
import PdfViewer from '../views/PdfViewer.vue'
import { useWeb3 } from '@/store/Web3'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/issue-1',
    name: 'issue1',
    component: Issue1
  },
  {
    path: '/issue-1/viewer',
    name: 'issue1-viewer',
    component: PdfViewer,
    beforeEnter: (to, from) => {
      const Web3 = useWeb3()
      return Web3.isLogged || { path: '/issue-1' }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
