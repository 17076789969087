import CollectionConfigInterface from "../lib/CollectionConfigInterface";
import * as Networks from "../lib/Networks";
import * as Marketplaces from "../lib/Marketplaces";
import whitelistAddresses from "./whitelist.json";

const CollectionConfig: CollectionConfigInterface = {
  testnet: Networks.ethereumTestnet,
  mainnet: Networks.ethereumMainnet,
  // The contract name can be updated using the following command:
  // yarn rename-contract NEW_CONTRACT_NAME
  // Please DO NOT change it manually!
  contractName: "NinjaBoyIssue1",
  tokenName: "Ninja Boy: Life after Death - Issue 1",
  tokenSymbol: "NJABOY",
  hiddenMetadataUri: "",
  maxSupply: 10000,
  whitelistSale: {
    price: 0.015,
    maxMintAmountPerTx: 100,
  },
  preSale: {
    price: 0.015,
    maxMintAmountPerTx: 100,
  },
  publicSale: {
    price: 0.015,
    maxMintAmountPerTx: 100,
  },
  contractAddress: "0xa99A9f2433FB86D23eC5e34abE70BE6E2b73C677",
  marketplaceIdentifier: "ninja-boy-issue-1",
  marketplaceConfig: Marketplaces.openSea,
  whitelistAddresses,
};

export default CollectionConfig;
