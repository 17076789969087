import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import { Buffer } from 'buffer'
window.Buffer = window.Buffer || Buffer

createApp(App)
  .use(router)
  .use(store)
  .mount('#app')

/*
document.addEventListener('keyup', function (e) {
  const keyCode = e.keyCode ? e.keyCode : e.which
  if (keyCode === 44) stopPrntScr()
})
function stopPrntScr () {
  const inpFld = document.createElement('input')
  inpFld.setAttribute('value', '.')
  inpFld.setAttribute('width', '0')
  inpFld.style.height = '0px'
  inpFld.style.width = '0px'
  inpFld.style.border = '0px'
  document.body.appendChild(inpFld)
  inpFld.select()
  document.execCommand('copy')
  inpFld.remove()
}
function AccessClipboardData () {
  try {
    window.clipboardData.setData('text', 'Access Restricted')
  } catch (err) {}
}

setInterval(AccessClipboardData, 300)
*/
