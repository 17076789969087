import { ethers, BigNumber } from 'ethers'
import { defineStore } from 'pinia'
import { ExternalProvider, Web3Provider } from '@ethersproject/providers'
import detectEthereumProvider from '@metamask/detect-provider'

import NetworkConfigInterface from '../../../smart-contract/lib/NetworkConfigInterface'
import CollectionConfig from '../../../smart-contract/config/CollectionConfig'
import NftContractType from '../scripts/lib/NftContractType'
import Whitelist from '../scripts/lib/Whitelist'

import { markRaw } from 'vue'

import CollectionsManifest from '../scripts/lib/CollectionsManifest.json'

import axios from 'axios'

// eslint-disable-next-line
const ContractAbi = require(`../../../smart-contract/artifacts/contracts/${CollectionConfig.contractName}.sol/${CollectionConfig.contractName}.json`).abi

interface State {
  contract: NftContractType|null,
  provider: Web3Provider|null,
  userAddress: string|null;
  network: ethers.providers.Network|null;
  networkConfig: NetworkConfigInterface;
  totalSupply: number;
  maxSupply: number;
  maxMintAmountPerTx: number;
  tokenPrice: BigNumber;
  isPaused: boolean;
  loading: boolean;
  isWhitelistMintEnabled: boolean;
  isUserInWhitelist: boolean;
  merkleProofManualAddress: string;
  errorMessage: string|JSX.Element|null;
  accessToken: string|null
}

const defaultState: State = {
  contract: null,
  provider: null,
  userAddress: null,
  network: null,
  networkConfig: CollectionConfig.mainnet,
  totalSupply: 0,
  maxSupply: 0,
  maxMintAmountPerTx: 0,
  tokenPrice: BigNumber.from(0),
  isPaused: true,
  loading: false,
  isWhitelistMintEnabled: false,
  isUserInWhitelist: false,
  merkleProofManualAddress: '',
  errorMessage: null,
  accessToken: null
}

export const useWeb3 = defineStore('Web3', {
  state: () => defaultState,
  actions: {
    async init () {
      const browserProvider = await detectEthereumProvider() as ExternalProvider
      this.provider = markRaw(new ethers.providers.Web3Provider(browserProvider))
      this.registerWalletEvents(browserProvider)
      await this.initWallet()
    },
    registerWalletEvents (browserProvider: ExternalProvider) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      browserProvider.on('accountsChanged', () => {
        this.initWallet()
      })

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      browserProvider.on('chainChanged', () => {
        window.location.reload()
      })
    },
    async initWallet () {
      // console.log('Init Wallet!')

      const walletAccounts = await this.provider?.listAccounts()

      this.$reset()

      if (!walletAccounts || walletAccounts?.length === 0) return

      const network = await this.provider?.getNetwork()
      let networkConfig: NetworkConfigInterface
      if (!network) return
      if (network.chainId === CollectionConfig.mainnet.chainId) {
        networkConfig = CollectionConfig.mainnet
      } else if (network.chainId === CollectionConfig.testnet.chainId) {
        networkConfig = CollectionConfig.testnet
      } else {
        this.setError('Unsupported network!')
        return
      }

      this.$patch({
        userAddress: walletAccounts[0].toLowerCase(),
        network: markRaw(network),
        networkConfig
      })

      if (await this.provider?.getCode(CollectionConfig.contractAddress!) === '0x') {
        this.setError('Could not find the contract, are you connected to the right chain?')
        return
      }

      this.contract = markRaw(new ethers.Contract(
        CollectionConfig.contractAddress!,
        ContractAbi,
        this.provider?.getSigner()
      )) as unknown as NftContractType

      this.refreshContractState()
    },
    async refreshContractState () {
      if (!this.contract) return
      // console.log('Refresh contract!')
      this.$patch({
        maxSupply: (await this.contract.maxSupply()).toNumber(),
        totalSupply: (await this.contract.totalSupply()).toNumber(),
        maxMintAmountPerTx: (await this.contract.maxMintAmountPerTx()).toNumber(),
        tokenPrice: await this.contract.cost(),
        isPaused: await this.contract.paused(),
        isWhitelistMintEnabled: await this.contract.whitelistMintEnabled(),
        isUserInWhitelist: Whitelist.contains(this.userAddress ?? '')
      })
    },
    setError (error: any = null) {
      let errorMessage = 'Unknown error...'

      console.log(error)

      if (error === null || typeof error === 'string') {
        errorMessage = error
      } else if (typeof error === 'object') {
        // Support any type of error from the Web3 Provider...
        if (error?.error?.message !== undefined) {
          errorMessage = error.error.message
        } else if (error?.data?.message !== undefined) {
          errorMessage = error.data.message
        } else if (error?.message !== undefined) {
          errorMessage = error.message
        }
      }

      this.errorMessage = errorMessage === null ? null : errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
    },
    async connectWallet () {
      try {
        await this.provider?.provider?.request!({ method: 'eth_requestAccounts' })

        this.initWallet()
      } catch (e) {
        this.setError(e)
      }
    },
    copyMerkleProofToClipboard (address: string): boolean {
      // console.log('Copy address!', address)
      const merkleProof = Whitelist.getRawProofForAddress(this.userAddress ?? address)

      if (merkleProof.length < 1) {
        return false
      }

      navigator.clipboard.writeText(merkleProof)
      return true
    },
    setMerkleProofManualAddress (address: string): void {
      this.merkleProofManualAddress = address
    },
    async mintTokens (amount: number): Promise<void> {
      try {
        this.loading = true
        const transaction = await this?.contract?.mint(amount, { value: this.tokenPrice.mul(amount) })
        /*
        toast.info(<>
          Transaction sent! Please wait...<br/>
          <a href={this.generateTransactionUrl(transaction.hash)} target="_blank" rel="noopener">View on {this.state.networkConfig.blockExplorer.name}</a>
        </>);
        */
        if (!transaction) return
        const receipt = await transaction.wait()
        /*
        toast.success(<>
          Success!<br />
          <a href={this.generateTransactionUrl(receipt.transactionHash)} target="_blank" rel="noopener">View on {this.state.networkConfig.blockExplorer.name}</a>
        </>);
        */

        await this.refreshContractState()
        this.loading = false
      } catch (e) {
        this.setError(e)
        this.loading = false
      }
    },
    async whitelistMintTokens (amount: number): Promise<void> {
      try {
        this.loading = true
        const transaction = await this?.contract?.whitelistMint(amount, Whitelist.getProofForAddress(this.userAddress!), { value: this.tokenPrice.mul(amount) })

        /*
        toast.info(<>
          Transaction sent! Please wait...<br/>
          <a href={this.generateTransactionUrl(transaction.hash)} target="_blank" rel="noopener">View on {this.state.networkConfig.blockExplorer.name}</a>
        </>);
        */
        if (!transaction) return
        const receipt = await transaction.wait()
        /*
        toast.success(<>
          Success!<br />
          <a href={this.generateTransactionUrl(receipt.transactionHash)} target="_blank" rel="noopener">View on {this.state.networkConfig.blockExplorer.name}</a>
        </>);
        */

        await this.refreshContractState()
        this.loading = false
      } catch (e) {
        this.setError(e)
        this.loading = false
      }
    },
    async sendRequest (url: string, options: any = {}): Promise<any> {
      const { useAuth = false, data = null, extra = {} } = options
      // console.log(useAuth, data, extra)
      let headers = {}
      if (useAuth) {
        // eslint-disable-next-line
        headers = { 'Authorization': `Bearer ${this.accessToken}` }
      }
      try {
        return (await axios.request({
          method: data !== null ? 'post' : 'get',
          url: `${process.env.VUE_APP_API_URL}${url}`,
          headers,
          data,
          ...extra
        })).data
      } catch (err: any) {
        console.log(err)
        this.setError(err.message)
      }
    },
    async login (): Promise<void> {
      // const nonce = 1329137982173
      // const signature = await this.provider?.provider?.request!({ method: 'personal_sign', params: [`Time to read some: ${nonce}`, this.userAddress] })
      // console.log('Signature:', signature)
      let user
      const users = await this.sendRequest(`/users?publicAddress=${this.userAddress}`)
      if ((users || []).length > 0) user = users[0]
      else {
        user = await this.sendRequest('/users', { data: { publicAddress: this.userAddress } })
      }
      if (!user) return
      const signature = await this.provider
        ?.provider
        ?.request!({
          method: 'personal_sign',
          params: [`Time to read some: ${user.nonce}`, this.userAddress, '']
        })
      const loginResult = await this.sendRequest('/auth', { data: { publicAddress: this.userAddress, signature } })
      if (!loginResult) return
      const { accessToken } = loginResult
      this.accessToken = accessToken
    }
  },
  getters: {
    isMetamask (): boolean {
      return this?.provider?.connection?.url === 'metamask'
    },
    isWalletConnected (): boolean {
      return this.userAddress !== null
    },
    isContractReady (): boolean {
      return this.contract !== undefined
    },
    isSoldOut (): boolean {
      return this.maxSupply !== 0 && this.totalSupply >= this.maxSupply
    },
    isNotMainnet (): boolean {
      // console.log('==>', this?.network?.chainId, CollectionConfig.mainnet.chainId)
      return this.network !== null && this.network.chainId !== CollectionConfig.mainnet.chainId
    },
    generateContractUrl (): string {
      return this.networkConfig.blockExplorer.generateContractUrl(CollectionConfig.contractAddress!)
    },
    generateMarketplaceUrl (): string {
      return CollectionConfig.marketplaceConfig.generateCollectionUrl(CollectionConfig.marketplaceIdentifier, !this.isNotMainnet)
    },
    generateTransactionUrl (): (arg0: any) => string {
      return (transactionHash: any) => this.networkConfig.blockExplorer.generateTransactionUrl(transactionHash)
    },
    marketplaceName (): string {
      return CollectionConfig.marketplaceConfig.name
    },
    isLogged (): boolean {
      return this.accessToken !== null
    },
    collectionInfo (): (arg0: string) => any {
      return (collection: string) => (CollectionsManifest as any)[collection]
    }
  }
})
