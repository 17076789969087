
import { Options, Vue } from 'vue-class-component'
import CollectionStatus from '@/components/CollectionStatus.vue'
import MintWidget from '@/components/MintWidget.vue'
import { useWeb3 } from '@/store/Web3'

@Options({
  components: {
    CollectionStatus,
    MintWidget
  }
})
export default class HomeView extends Vue {
  Web3 = useWeb3();
  merkleProofManualAddress = ''
  merkleProofManualAddressStatus = null

  async login () {
    await this.Web3.login()
    console.log('Go!')
    this.$router.push({ name: 'issue1-viewer' })
  }
}
