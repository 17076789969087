
import { Options, Vue } from 'vue-class-component'
import { utils, BigNumber } from 'ethers'
import NetworkConfigInterface from '../../../smart-contract/lib/NetworkConfigInterface'

const props = {
  networkConfig: null,
  maxSupply: Number,
  totalSupply: Number,
  tokenPrice: BigNumber,
  maxMintAmountPerTx: Number,
  isPaused: Boolean,
  loading: Boolean,
  isWhitelistMintEnabled: Boolean,
  isUserInWhitelist: Boolean,
  mintTokens: null,
  whitelistMintTokens: null
}

@Options({
  props
})
export default class MintWidget extends Vue {
  networkConfig!: NetworkConfigInterface
  maxSupply!: number
  totalSupply!: number
  tokenPrice!: BigNumber
  maxMintAmountPerTx!: number
  isPaused!: boolean
  loading!: boolean
  isWhitelistMintEnabled!: boolean
  isUserInWhitelist!: boolean
  mintTokens!: (mintAmount: number) => Promise<void>
  whitelistMintTokens!: (mintAmount: number) => Promise<void>

  mintAmount = 0

  get canMint (): boolean {
    return !this.isPaused || this.canWhitelistMint
  }

  get canWhitelistMint (): boolean {
    return this.isWhitelistMintEnabled && this.isUserInWhitelist
  }

  get formattedPrice (): string {
    return utils.formatEther(this.tokenPrice.mul(this.mintAmount))
  }

  async mint (): Promise<void> {
    if (!this.isPaused) {
      await this.mintTokens(this.mintAmount)
      return
    }
    await this.whitelistMintTokens(this.mintAmount)
  }
}
